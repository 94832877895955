var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Monthly Plans","subtitle":"Browse and manage your monthly plans!","badge":_vm.total}}),_c('v-row',{staticClass:"mt-0 mb-1 justify-space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"}},[_c('filter-manager',{ref:"filterManager",attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('v-sheet',{staticClass:"mx-1",attrs:{"height":"28","width":"1","color":"blue-grey lighten-4"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"white","height":"32px"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-filter me-2 fs-12px"}),_vm._v(" "+_vm._s(_vm.options.Date ? ("Date: " + (_vm.options.Date)) : "Select Date From")+" ")])]}}]),model:{value:(_vm.dateMenuFrom),callback:function ($$v) {_vm.dateMenuFrom=$$v},expression:"dateMenuFrom"}},[_c('v-date-picker',{attrs:{"show-current":"","show-week":"","type":"month"},on:{"input":function($event){_vm.dateMenuFrom = false}},model:{value:(_vm.options.Date),callback:function ($$v) {_vm.$set(_vm.options, "Date", $$v)},expression:"options.Date"}})],1),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}})],1)])],1),_c('v-data-table',{staticClass:"elevation-2 table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","server-items-length":_vm.total,"headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"show-expand":"","expanded":_vm.expanded,"item-key":"id","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.date,"date-only":""}})]}},{key:"item.firstName",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item,"hide-roles":"","disable-open-link":""}})]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pa-4 inset-shadow",staticStyle:{"background":"#eceff1"}},[(item.projects.length)?_c('div',{staticClass:"font-weight-bold d-flex align-center mb-1"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","loading":_vm.selectedId == item.id,"disabled":_vm.selectedForApplyId == item.id},on:{"click":function($event){return _vm.saveConfirmed(item)}}},[_vm._v("Save")]),_c('v-btn',{attrs:{"small":"","color":"error","disabled":_vm.selectedId == item.id,"loading":_vm.selectedForApplyId == item.id},on:{"click":function($event){return _vm.apply(item)}}},[_vm._v(_vm._s(item.isApplied ? "Re-Apply" : "Apply"))])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.monthlyPlanHeader,"items":item.projects,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"200","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Projects Available!")])]},proxy:true},{key:"item.relativeWeight",fn:function(date){return [_c('div',{staticClass:"px-2 py-1",attrs:{"contenteditable":""},on:{"blur":function($event){return _vm.updatePlanRow($event, item, date.index, date.item)}}},[_vm._v(" "+_vm._s(date.item.relativeWeight || 0)+" ")])]}},{key:"item.percentage",fn:function(date){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("percentage")(date.item.percentage))+" ")])]}},{key:"item.monthlyHours",fn:function(date){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("digit")(date.item.monthlyHours,2))+" ")])]}},{key:"item.dailyHours",fn:function(date){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("digit")(date.item.dailyHours,2))+" ")])]}},{key:"item.actions",fn:function(ref){
    var index = ref.index;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red","icon":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.del(item, index)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]}}],null,true)})],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }